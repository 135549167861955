import { observer } from "mobx-react";
import { useAuthStore, useAvitoStore } from "../app/stores";
import styled from "styled-components";
import { AvitoAccountCard } from "../entities";
import { useEffect } from "react";
import { getAvitoAccounts } from "../shared";

const AvitoLines = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 15px;

  & > div:nth-child(odd) {
    background: #fafafa;
  }
  & > div:nth-child(even) {
    background: white;
  }
`;

const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fafafa;
  max-width: 100%;
  box-sizing: border-box;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media(max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 5px;
    p {
      margin: 0;
      padding: 6px 0px;
    }
    p:nth-child(3), p:nth-child(4), p:nth-child(5), p:nth-child(7) {
      display: none;
    }
  }
`;

const AvitoAccountList = observer(() => {
  const { accountsLoaded, accounts } = useAvitoStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (!accountsLoaded) {
      getAvitoAccounts({ token: user.token });
    }
  });

  return (
    <AvitoLines>
      <FirstLine>
        <p>Номер аккаунта</p>
        <p>Имя</p>
        <p>Телефон</p>
        <p>Почта</p>
        <p>Активен</p>
        <p>Ссылка</p>
        <p>Дата создания</p>
        <p>Действия</p>
      </FirstLine>
      {accounts.map((e: any) => (
        <AvitoAccountCard {...e} cookiesExists={!!e.avitoCookies} />
      ))}
    </AvitoLines>
  );
});

export default AvitoAccountList;
