import { observer } from "mobx-react";
import styled from "styled-components";
import { Button, Flex, Modal, Popconfirm, message } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { deleteAvitoAccount, refreshAvitoAccount } from "../shared";
import { useAuthStore, useAvitoStore } from "../app/stores";
import disableAvitoAccount from "../shared/api/disableAvitoAccount";
import enableAvitoAccount from "../shared/api/enableAvitoAccount";
import React from "react";
import { PacmanLoader } from "react-spinners";
import api from "../app/api";

const AccountCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a {
    color: #3b5fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    padding-top: 10px;
    padding-bottom: 10px;
    p {
      margin: 0;
      padding: 0;
    }
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(7) {
      display: none;
    }
  }
`;

const Active = styled.div<{ color: "green" | "red" }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => (color === "green" ? "#34a550" : "#e04646")};
  border-radius: 50px;
`;

const SyncButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvitoAccountCard = observer(
  ({
    id,
    client_number,
    name,
    phone,
    email,
    profile_url,
    created_at,
    active,
    cookiesExists
  }: any) => {
    const { accounts, setAccounts } = useAvitoStore();
    const { user } = useAuthStore();

    const refreshAccount = async () => {
      const response = await refreshAvitoAccount(id);
      // const updatedAccounts = accounts
      //   .filter((e) => e.id !== id)
      //   .concat(response?.data);
      // setAccounts(updatedAccounts);

      if(response?.status === 201) message.success("Отправлен запрос на обновление. Аккаунт обновится в скором времени!")
      else {
        console.log(response)
        message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
      }
    };

    const onConfirm = async () => {
      const response = await disableAvitoAccount(id, user.token)

      const account = response?.data
      if(response?.status !== 200 || !account) {
        console.log(response)
        return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
      }

      message.success("Аккаунт успешно отключен");
      setAccounts(accounts.map(acc => {
        if(acc.id === id) acc.active = account.active
        return acc
      }));
    };

    const onEnable = async () => {
      const response = await enableAvitoAccount(id, user.token)

      if(response?.status === 400)return message.error("Аккаунт не валиден")
      if(response?.status === 403)return message.error("Пользователь не валиден")

      const account = response?.data
      if(response?.status !== 200 || !account) {
        console.log(response, account)
        return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
      }

      message.success("Аккаунт был успешно включен");
      setAccounts(accounts.map(acc => {
        if(acc.id === id) acc.active = account.active
        return acc
      }));
    }

    const [ loader, setLoader ] = React.useState(false)
    const [ isLogin, setIsLogin ] = React.useState(false)

    async function loginAvitoAccount() {
      if(loader || isLogin)return

      setLoader(true)
      const result = await api.post(`amo/browser/login`, {
        avitoAccountId: id
      })

      setLoader(false)
      if(result.status === 200) {
        setIsLogin(true)
      }
      else {
        if(result.status === 404) {
          message.error("Аккаунт не найден! Обновите страницу");
        }
        else {
          message.error("Что-то пошло не так! Подробности в консоли разработчка");
          console.log(result)
        }
      }
    }
    async function loginAvitoAccountSubmit() {
      if(loader || !isLogin)return

      setLoader(true)
      const result = await api.post(`amo/browser/login/submit`, {
        avitoAccountId: id
      })

      setIsLogin(false)
      setLoader(false)

      if(result.status === 200) {
        message.success("Авторизация была пройдена.")
        setTimeout(() => {
          window.location = window.location
        }, 2000)
      }
      else if(result.status === 204) {
        message.error("Авторизация не была завершена. Повторите попытку")
      }
      else {
        if(result.status === 404) {
          message.error("Аккаунт не найден! Обновите страницу");
        }
        else if(result.status === 401) {
          message.error("Вы не запускали процесс авторизации авито аккаунта, либо он был прерван. Обновите страницу");
        }
        else {
          message.error("Что-то пошло не так! Подробности в консоли разработчка");
          console.log(result)
        }
      }
    }

    return (
      <>
        <AccountCard>
          <p>{client_number}</p>
          <p>{name}</p>
          <p>{phone}</p>
          <p>{email}</p>
          <p>
            <Active color={active ? "green" : "red"} />
          </p>
          <p>
            <a href={profile_url} target="_blank" rel="noreferrer">
              {profile_url}
            </a>
          </p>
          <p>{new Date(created_at).toLocaleString()}</p>
          <Flex gap={10} align="center">
            {active ? (
              <Popconfirm
                title="Перепривязать аккаунт?"
                description="Если аккаунт неактивен, то перепривяжите аккаунт"
                onConfirm={() => refreshAccount()}
                okText="Перепривязать"
                cancelText="Отмена"
              >
                <SyncButton type="default" style={{ width: 32 }}>
                  <SyncOutlined />
                </SyncButton>
              </Popconfirm>
            ) : ''}

            {active ? (
              <Popconfirm
                title="Отключить аккаунт авито?"
                description="При отключении аккаунта вы не сможете поддержать диалог с пользователями, которым отвечали с этого аккаунта"
                onConfirm={onConfirm}
                okText="Отключить"
                cancelText="Отмена"
              >
                <Button danger style={{ width: 110 }}>Отключить</Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Включить аккаунт авито?"
                description="При включении аккаунта мы проверим его на валидность и после включения Вы сможете получать сообщения"
                onConfirm={onEnable}
                okText="Включить"
                cancelText="Отмена"
              >
                <Button style={{ width: 110 }}>Включить</Button>
              </Popconfirm>
            )}

            {active && !isLogin ? (
              <Popconfirm
                title={"Авторизовать авито аккаунт?"}
                description={"После авторизации авито аккаунта будет доступна возможность загружать видео с amoCRM в авито чат\n\nВНИМАНИЕ!\nАвторизация авито аккаунта производится при помощи поддержки."}
                onConfirm={loginAvitoAccount}
                okText={"Авторизовать"}
                cancelText={"Отмена"}
              >
                <Button>Login</Button>
              </Popconfirm>
            ) : ''}
          </Flex>

          {loader && !isLogin ? (
            <div style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              background: 'rgba(0, 0, 0, .5)',
              top: '0',
              left: '0',
              zIndex: '500',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <PacmanLoader
                color={"#75a49a"}
                size={30}
              />
            </div>
          ) : null}
          {isLogin ? (
            <Modal title={"Ожидание..."} open={true} onOk={loginAvitoAccountSubmit} onCancel={() => {
              setIsLogin(false)
            }} footer={[
              <Button key={"back"} onClick={() => {
                setIsLogin(false)
              }}>
                Отмена
              </Button>,
              <Button key={"submit"} type={"primary"} onClick={loginAvitoAccountSubmit} loading={loader}>
                Подтвердить
              </Button>
            ]}>
              <p>Произведите вход в аккаунт авито.</p>
              <p>После успешного входа подтвердите действие, нажав "Подтвердить"</p>
            </Modal>
          ) : null}
        </AccountCard>
      </>
    );
  }
);

export default AvitoAccountCard;
